import React from "react";
import axios from "axios";
import URL from "../config/config";
import {
	Table,
	Input,
	Button,
	Icon
	// Divider, Tag, Pagination, Input, Button, Icon
} from "antd";
import "antd/dist/antd.css";
// import Highlighter from "react-highlight-words";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";
import timeConvert from "../helper/data_convert";

export default class XMLData extends React.Component {
	constructor() {
		super();
		this.state = {
			datas: [],
			loading: false,
			searchText: "",
			search_data: [],
			xml_prettify: true
		};
	}
	getDatas() {
		let url = URL.xmlData;

		this.setState({
			loading: true
		});
		axios.get(url).then(response => {
			console.log(response);
			this.setState({
				datas: response.data,
				loading: false
			});
			// console.log(this.state.datas);
		});
	}
	componentWillMount() {
		this.getDatas();
	}
	// Search by Keyword
	handleChange(event) {
		this.setState({ searchText: event.target.value });
	}
	handleSearch(value) {
		this.setState({ searchText: value });
	}
	render() {
		function searchingFor(searchText) {
			return function(x) {
				return (
					x.vin.toLowerCase().includes(searchText.toLowerCase()) || !searchText
				);
			};
		}
		// search filter go here

		let data = this.state.datas.map(data => ({
			key: data._id,
			deviceID: data.deviceID ? data.deviceID : " ",
			xml_payload: data.xml_payload,
			readableData: data.readableData,
			date: timeConvert(data.createdAt)
		}));

		const columns = [
			{
				title: "Date Create",
				dataIndex: "date",
				key: "date",
				width: "15%",
				sorter: (a, b) => {
					let _a = new Date(a.date);
					let _b = new Date(b.date);
					return _a > _b ? 1 : -1;
				},
				sortDirections: ["descend", "ascend"]
			},
			// {
			// 	title: "vin",
			// 	dataIndex: "vin",
			// 	key: "vin",
			// 	width: "15%"
			// 	// ...this.getColumnSearchProps("address"),
			// 	// sorter: (a, b) => a.vin - b.vin,
			// 	// sortDirections: ["descend", "ascend"]
			// },
			{
				title: "deviceID",
				dataIndex: "deviceID",
				key: "deviceID",
				width: "15%",
				sorter: (a, b) => (a.deviceID > b.deviceID ? 1 : -1),
				sortDirections: ["descend", "ascend"]
			},
			{
				title: "readableData",
				dataIndex: "readableData",
				key: "readableData",
				width: "40%",
				render: text => (
					<div
						style={{
							// color: "#ffcc00",
							overflowX: "scroll",
							// width: 400,
							maxWidth: 700,
							height: 200
							// backgroundColor: "#000"
						}}
					>
						{text && !text.includes("Data type does not support yet!") ? (
							<ReactJson
								src={JSON.parse(text)}
								indentWidth={0}
								name={false}
								displayDataTypes={false}
							/>
						) : (
							<p>{text}</p>
						)}
					</div>
				)
			},
			// {
			// 	title: "xml payload",
			// 	dataIndex: "xml_payload",
			// 	key: "xml_payload",
			// 	width: "40%",
			// 	render: text => (
			// 		<div
			// 			style={{
			// 				// color: "#00ef00",
			// 				overflowX: "scroll",
			// 				// width: 400,
			// 				maxWidth: 600,
			// 				height: 200
			// 				// backgroundColor: "#000"
			// 			}}
			// 		>
			// 			<XMLViewer xml={text} />
			// 		</div>
			// 	)
			// },
			{
				title: () => (
					<div>
						xml payload{" "}
						<Button
							// type="primary"
							size="small"
							shape="round"
							icon="code"
							onClick={() =>
								this.setState({ xml_prettify: !this.state.xml_prettify })
							}
						>
							{!this.state.xml_prettify ? (
								<span>prettify</span>
							) : (
								<span>string</span>
							)}
						</Button>
					</div>
				),
				dataIndex: "xml_payload",
				key: "xml_payload",
				width: "30%",
				render: text => (
					<div
						style={{
							// color: "#00ef00",
							overflowX: "scroll",
							// width: 400,
							maxWidth: 600,
							height: 200
							// backgroundColor: "#000"
						}}
					>
						{this.state.xml_prettify ? (
							<XMLViewer xml={text} />
						) : (
							<span>{text}</span>
						)}
					</div>
				)
			}
		];

		return (
			<div>
				<Table
					columns={columns}
					dataSource={data}
					size="middle"
					bordered
					loading={this.state.loading}
					title={() => <h2>XML Data</h2>}
					pagination={{ pageSize: 50 }}
				/>
			</div>
		);
	}
}
